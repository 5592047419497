<template>
  <div>
    <add-new-type :is-add-new-type-sidebar-active.sync="isAddNewTypeSidebarActive"
      @clickaddnewquestion="clickaddnewquestion" />
    <add-edit-type :is-add-edit-type-sidebar-active.sync="isAddEditTypeSidebarActive" :editdata="editdata"
      @ClickEditQuestiondata="ClickEditQuestiondata" />
    <b-modal id="move-questions" size="lg" centered title="Move Questions" hide-footer>
      <div style="overflow: hidden">
        <div class="modal_content">
          <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
            <b-thead head-variant="light">
              <b-tr>
                <b-th></b-th>
                <b-th>No.</b-th>
                <b-th>Level Name</b-th>
                <b-th>No of Question</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(level, index) in allLevels">
                <b-tr :key="index + 1">
                  <b-td>
                    <b-form-checkbox :id="level._id" :disabled="routedata == level._id ? true : false"
                      v-model="level.checkbox" v-on:change="levelCheckBox(index)"></b-form-checkbox></b-td>
                  <b-td> {{ index + 1 }}</b-td>
                  <b-td>
                    {{ level.levelName }}
                  </b-td>
                  <b-td>{{ level.noOfQuestion }}</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <div class="modal-footer">
            <b-button @click="moveQuestion" :disabled="loadingMoveQuestion" style="margin-left: 7px"
              variant="primary">{{
      loadingMoveQuestion ? "Saving" : "Save" }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-xl-gallery-img" hide-footer size="xl" centered title="Gallery">
      <div class="modal_content">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <b-form-input v-model="gallerySearchQuery" @input="filterGalleryImages" placeholder="Search..." />
            </div>
          </b-col>
        </b-row>
        <b-tabs style="height: 430px; overflow: scroll">
          <b-tab active @click="recent_image_add_in_gallery">
            <template #title>
              <span>Recently Uploaded </span>
            </template>
            <pagination class="m-t-10" :data="galleryImages" :limit=2
              @pagination-change-page="recent_image_add_in_gallery">
            </pagination>
            <div>
              <b-row class="match-height">
                <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in galleryImages.data"
                  :key="galleryimg.img_id" @click="saveQuestionBackground(galleryimg.gallery_img)" v-lazy-container="{
      selector: 'img',
      error:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
      loading:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    }">
                  <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                    :data-src="galleryimg.gallery_img" />
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab @click="recent_image_used_in_gallery">
            <template #title>
              <span>Recently Used</span>
            </template>
            <pagination class="m-t-10" :data="galleryImages" :limit=2
              @pagination-change-page="recent_image_used_in_gallery">
            </pagination>
            <div>
              <b-row class="match-height">
                <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in galleryImages.data"
                  :key="galleryimg.img_id" @click="saveQuestionBackground(galleryimg.gallery_img)" v-lazy-container="{
      selector: 'img',
      error:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
      loading:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    }">
                  <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                    :data-src="galleryimg.gallery_img" />
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal id="modal-xl-videos" hide-footer size="xl" centered title="Videos">
      <div class="modal_content">
        <b-tabs style="height: 430px; overflow: scroll">
          <b-tab>
            <template #title>
              <span>{{
      selectVideoOrThumbnails == "1" ? "Video Thumbnails" : "Videos"
    }}
              </span>
            </template>
            <div>
              <b-row class="match-height">
                <b-col md="2" lg="2" style="padding: 8px; position: relative" v-for="videos in allVideos"
                  :key="videos._id" @click="saveQuestionVideosOrBg(videos)" v-lazy-container="{
      selector: 'img',
      error:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
      loading:
        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    }">
                  <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                    :data-src="videos.videoThumbnail" v-if="selectVideoOrThumbnails == '1'" />
                  <div style="width: 200px; height: auto; object-fit: contain" v-if="selectVideoOrThumbnails == '2'">
                    <b-embed type="video" aspect="4by3" controls :poster="videos.videoThumbnail">
                      <source :src="videos.video_uri" type="video/webm" />
                      <source :src="videos.video_uri" type="video/x-m4v" />
                      <source :src="videos.video_uri" type="video/mp4" />
                      <source :src="videos.video_uri" type="video/avi" />
                    </b-embed>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal id="qr-code-question" hide-footer size="md" centered title="Qr code">
      <div class="modal_content">
        <canvas id="qrcode" style="width:200px;height: 200px;"></canvas>

        <b-form-checkbox v-model="publicStatus" v-on:change="updateQuestionStatus(1)">
          <span>
            Direct public
          </span>
        </b-form-checkbox>
        <b-form-checkbox v-model="revisionStatus" v-on:change="updateQuestionStatus(2)">
          <span>
            Send to Revision
          </span>
        </b-form-checkbox>
      </div>
    </b-modal>
    <b-card no-body>
      <b-tabs v-model="tabIndex">
        <b-tab v-if="themeQuestion" title="Theme Questions" @click="checkSelectedTab(0)"></b-tab>
        <b-tab title="Questions" @click="checkSelectedTab(1)"> </b-tab>
        <b-tab title="Level Details" @click="checkSelectedTab(2)"></b-tab>
      </b-tabs>
    </b-card>
    <div v-if="tabIndex == 0">
      <Question ref="questionComponent"/>
    </div>
    <div v-if="tabIndex == 1">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-breadcrumb :items="items" />
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" class="d-inline-block mr-1" v-if="moveButtonStatus"
                  v-b-modal.move-questions>
                  <span class="text-nowrap">Move</span>
                </b-button>
                <b-form-input @input="questionsFilter" v-model="searchQuery" class="d-inline-block mr-1"
                  placeholder="Search..." />
                <b-button variant="primary" @click="isAddNewTypeSidebarActive = true">
                  <span class="text-nowrap">Add Question</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-overlay :show="show" rounded="sm">
          <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
            <b-thead head-variant="light">
              <b-tr>
                <b-th>
                  <b-form-checkbox v-on:change="checkboxdata()" v-model="allCheckBox">
                  </b-form-checkbox>
                </b-th>
                <b-th>No.</b-th>
                <b-th>Preview</b-th>
                <b-th>Question Heading</b-th>
                <b-th>Question type</b-th>
                <b-th>Revision Status</b-th>
                <b-th>Created At</b-th>
                <b-th>Updated At</b-th>
                <b-th>Action</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(question_data, index) in table_data">
                <b-tr :key="index" :class="{ 'highlight-row': questionColorChange == question_data._id }">
                  <b-td><b-form-checkbox :id="checkboxQuestion[index]['_id']"
                      v-model="checkboxQuestion[index]['checkBoxStatus']">
                    </b-form-checkbox>
                  </b-td>
                  <b-td v-if="question_data.questionNo">
                    {{ question_data.questionNo }}
                  </b-td>
                  <b-td>
                    <feather-icon @click="preview_question(index)" v-b-modal.modal-lg height="20px" width="20px"
                      icon="EyeIcon" />
                  </b-td>
                  <b-td>
                    <span
                      v-if="question_data.revisionObj && question_data.revisionObj.revisionNo != 1 && question_data.revisionObj.revisionStatus == false"
                      class="curser-pointer" style="font-weight: bold;
                            font-size: 20px;
                            color: red;
                            cursor: pointer;margin-right: 5px;">
                      <span style="display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: red;">
                      </span>
                    </span>
                    {{ question_data.title }}
                  </b-td>
                  <b-td>{{ question_data.questionType }}</b-td>
                  <b-td>
                    <div v-if="question_data.revisionStatus == false">
                      <b-badge pill variant="danger">
                        Pending
                      </b-badge>
                    </div>
                    <div v-else>
                      <b-badge pill variant="success">
                        Approved
                      </b-badge>
                    </div>
                  </b-td>
                  <b-td style="width: 138px">
                    <div>
                      {{ dataSplitFunction(question_data.created_at).date }}
                    </div>
                    <div>
                      {{ dataSplitFunction(question_data.created_at).time }}
                    </div>
                  </b-td>
                  <b-td style="width: 138px">
                    <div>
                      {{ dataSplitFunction(question_data.updated_at).date }}
                    </div>
                    <div>
                      {{ dataSplitFunction(question_data.updated_at).time }}
                    </div>
                  </b-td>
                  <b-td>
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                      </template>
                      <b-dropdown-item @click="editquestiondata(question_data)">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-b-modal.modal-edit-history @click="EditHistory(question_data._id)">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">View History</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deletequestiondata(question_data._id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-b-modal.modal-feed-back @click="feedback(question_data._id)">
                        <feather-icon icon="RefreshCcwIcon" />
                        <span class="align-middle ml-50">feedback</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <b-modal id="modal-feed-back" size="lg" centered title="Feedback">
          <img class="model_backgroundimage"
            src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.010193975588125070.39484294051567080.5217275479947419.jpeg" alt=""/>

          <div style="overflow: auto" class="modal_feedback_data">
            <div v-for="(data, index) in feedbackData" :key="index">
              <div style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  margin-top: 10px;
                " v-for="(feedback, ind) in data.reply" :key="ind">
                <div v-if="ind == 0" style="width: 12%">
                  <b-avatar size="70px" :src="feedback.avatar" />
                </div>
                <div v-if="ind > 0" style="
                    width: 50%;
                    background: white;
                    border-radius: 10px;
                    margin-left: 283px;
                  ">
                  <div style="font-size: 20px; font-weight: 900; color: black">
                    {{ feedback.userName }}
                  </div>
                  <div style="color: black">{{ feedback.reply }}</div>
                  <span style="color: black; font-size: 15px; font-weight: 900">{{ feedback.replyDate }}</span>
                  <span style="
                      color: black;
                      margin-left: 8px;
                      font-size: 15px;
                      font-weight: 900;
                    ">{{ feedback.replyTime }}</span>
                  <span style="color: black; margin-left: 8px; font-size: 15px" v-if="feedback.likes.length != 0">{{
      feedback.likes.length }}
                    <feather-icon icon="ThumbsUpIcon" size="16" class="align-middle text-body" /></span>
                  <span style="color: black; margin-left: 8px; font-size: 15px" v-else>{{ feedback.likes.length }}
                    <feather-icon icon="ThumbsUpIcon" size="16" class="align-middle text-body" /></span>

                  <span style="color: black" v-if="feedback.disLikes.length != 0">{{ feedback.disLikes.length }}
                    <feather-icon icon="ThumbsDownIcon" size="16" class="align-middle text-body" />
                  </span>
                  <span style="color: black" v-else>{{ feedback.disLikes.length }}
                    <feather-icon icon="ThumbsDownIcon" size="16" class="align-middle text-body" />
                  </span>
                </div>

                <div v-if="ind == 0" style="width: 50%; background: white; border-radius: 10px">
                  <div style="font-size: 20px; font-weight: 900; color: black">
                    {{ feedback.userName }}
                  </div>
                  <div style="color: black">{{ feedback.reply }}</div>
                  <span style="color: black; font-size: 15px; font-weight: 900">{{ feedback.replyDate }}</span>
                  <span style="
                      color: black;
                      margin-left: 8px;
                      font-size: 15px;
                      font-weight: 900;
                    ">{{ feedback.replyTime }}</span>
                  <span style="color: black; margin-left: 8px; font-size: 15px" v-if="feedback.likes.length != 0">{{
      feedback.likes.length }}
                    <feather-icon icon="ThumbsUpIcon" size="16" class="align-middle text-body" /></span>
                  <span style="color: black; margin-left: 8px; font-size: 15px" v-else>{{ feedback.likes.length }}
                    <feather-icon icon="ThumbsUpIcon" size="16" class="align-middle text-body" /></span>

                  <span style="color: black" v-if="feedback.disLikes.length != 0">{{ feedback.disLikes.length }}
                    <feather-icon icon="ThumbsDownIcon" size="16" class="align-middle text-body" />
                  </span>
                  <span style="color: black" v-else>{{ feedback.disLikes.length }}
                    <feather-icon icon="ThumbsDownIcon" size="16" class="align-middle text-body" />
                  </span>
                </div>
                <div v-if="ind > 0" style="width: 8%; margin-left: 10px">
                  <b-avatar size="70px" :src="feedback.avatar" />
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal id="modal-edit-history" size="lg" centered title="View History">
          <div style="overflow: hidden">
            <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Question Heading</b-th>
                  <b-th>Question type</b-th>
                  <b-th style="width: 150px;">CreatedBy</b-th>
                  <b-th style="width: 150px;">EditBy</b-th>
                  <b-th>Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(question_data, index) in edithistory">
                  <b-tr :key="index">
                    <b-td>
                      {{ question_data.title }}
                    </b-td>
                    <b-td>{{ question_data.questionType }}</b-td>
                    <b-td>
                      <div v-if="question_data.userId">
                        {{ question_data.userId.name }}
                        <br>
                        ({{ question_data.userId.date }})
                        <br>
                        {{ question_data.userId.time }}
                      </div>
                      <div v-else>
                        N/A
                      </div>
                    </b-td>
                    <b-td>
                      <div v-if="question_data.editby">
                        {{ question_data.editby.name }}
                        <br>
                        ({{ question_data.editby.date }})
                        <br>
                        {{ question_data.editby.time }}
                      </div>
                      <div v-else>
                        N/A
                      </div>
                    </b-td>
                    <b-td>
                      <b-dropdown variant="link" no-caret>
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item @click="preview_Edit_Question(index)" v-b-modal.modal-edit-lg>
                          <feather-icon height="20px" width="20px" icon="EyeIcon" />
                          <span class="align-middle ml-50">Preview</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="restoreIEditQuestion(question_data._id)">
                          <feather-icon icon="RefreshCcwIcon" />
                          <span class="align-middle ml-50">Restore</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-modal>
        <b-modal id="modal-edit-lg" hide-footer size="lg" centered title="Edit Question Preview">
          <div>
            <div v-for="(preview, index) in previewbackground" :key="index">
              <div v-if="preview.data == 'image'">
                <img class="model_backgroundimage" :src="preview.values" />
              </div>
              <div v-else :style="'background: ' +
      preview.values +
      ';height: 100%;width: 100%;position: absolute;top: 0;left: 0;'
      "></div>
            </div>
            <div class="modal_content">
              <div v-for="(preview, index) in previewTitle" :key="index">
                <div v-for="(preview, index) in previewQuestionType" :key="index">
                  <h3>
                    {{ preview.data }}
                  </h3>
                </div>
                <h3 style="
                    text-align: center;
                    background-color: #ffa500;
                    color: white;
                    margin-left: 10%;
                    margin-right: 10%;
                    width: 80%;
                    font-size: 1.8rem;
                    height: auto;
                    border-radius: 15px;
                  ">
                  {{ preview.data }}
                </h3>
              </div>
              <div>
                <b-row v-for="(preview, index) in previewImage" :key="index">
                  <b-col cols="3" class="text-center text-white">
                    <img style="height: 100px; margin: 5px 0" :src="preview.data" />
                  </b-col>
                </b-row>
              </div>
              <div v-for="(preview, index) in previewArray" :key="index">
                <span v-if="preview.fieldType == 'image'">
                  <img style="width: 150px; height: 150px" :src="preview.data" />
                </span>
                <span v-if="preview.fieldType == 'text'">
                  <h6 style="margin-left: 10px; color: green">
                    {{ preview.data }}
                  </h6>
                </span>
              </div>

              <b-row>
                <b-col :cols="12 / preview.data.length + 1" v-for="(preview, index) in previewObject" :key="index"
                  class="text-center text-white">
                  <div v-if="preview.data.fieldType == 'image'">
                    <img style="width: 150px; height: 150px" :src="preview.data.value" />
                  </div>
                  <div v-if="preview.data.fieldType == 'text'">
                    <div style="background: black">
                      {{ preview.data.value }}
                    </div>
                  </div>
                  <div v-if="preview.data.fieldType == 'text'">
                    <div style="background: green">
                      {{ preview.data.isCorrect }}
                    </div>
                  </div>
                  <div v-if="preview.data.fieldType == 'image'">
                    <div style="background: green">
                      {{ preview.data.isCorrect }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-for="(preview, index) in previewText" :key="index">
                <h6 v-if="preview.fieldType == 'text'">
                  {{ preview.data }}
                </h6>
              </div>
            </div>
          </div>
          <div class="modal_content">
            <div class="modal-footer">
              <button @click="Editquestionback" class="btn btn-primary">
                back
              </button>
              <button @click="EditquestionNext" class="btn btn-primary">
                next
              </button>
              <button type="button" v-b-modal.modal-json class="btn btn-primary">
                Open Json
              </button>
              <button type="button" @click="closejsonmodel()" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </b-modal>
        <b-modal id="modal-json" size="lg" centered title="Json Preview">
          <div style="overflow: hidden">
            <textarea style="width: 100%; height: 300px" v-model="jsonShowdata"></textarea>
          </div>
        </b-modal>
        <b-modal id="modal-lg" hide-footer size="lg" centered title="Question Preview">
          <div>
            <div v-for="(preview, index) in previewbackground" :key="index">
              <div v-if="preview.data == 'image'">
                <img class="model_backgroundimage" :src="preview.values" />
              </div>
              <div v-else :style="'background: ' +
      preview.values +
      ';height: 100%;width: 100%;position: absolute;top: 0;left: 0;'
      "></div>
            </div>
            <div class="modal_content">
              <div v-for="(preview, index) in previewTitle" :key="index">
                <div v-for="(preview, index) in previewQuestionType" :key="index">
                  <h3>
                    {{ preview.data }}
                  </h3>
                </div>
                <h3 style="
                    text-align: center;
                    background-color: #ffa500;
                    color: white;
                    margin-left: 10%;
                    margin-right: 10%;
                    width: 80%;
                    font-size: 1.8rem;
                    height: auto;
                    border-radius: 15px;
                  ">
                  {{ preview.data }}
                </h3>
              </div>
              <div>
                <b-row v-for="(preview, index) in previewImage" :key="index">
                  <b-col cols="3" class="text-center text-white">
                    <img style="height: 100px; margin: 5px 0" :src="preview.data" />
                  </b-col>
                </b-row>
              </div>
              <div v-for="(preview, index) in previewArray" :key="index">
                <span v-if="preview.fieldType == 'image'">
                  <img style="width: 150px; height: 150px" :src="preview.data" />
                </span>
                <span v-if="preview.fieldType == 'text'">
                  <h6 style="margin-left: 10px; color: green">
                    {{ preview.data }}
                  </h6>
                </span>
              </div>

              <b-row>
                <b-col :cols="12 / preview.data.length + 1" v-for="(preview, index) in previewObject" :key="index"
                  class="text-center text-white">
                  <div v-if="preview.data.fieldType == 'image'">
                    <img style="width: 150px; height: 150px" :src="preview.data.value" />
                  </div>
                  <div v-if="preview.data.fieldType == 'text'">
                    <div style="background: black">
                      {{ preview.data.value }}
                    </div>
                  </div>
                  <div v-if="preview.data.fieldType == 'text'">
                    <div style="background: green">
                      {{ preview.data.isCorrect }}
                    </div>
                  </div>
                  <div v-if="preview.data.fieldType == 'image'">
                    <div style="background: green">
                      {{ preview.data.isCorrect }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-for="(preview, index) in previewText" :key="index">
                <h6 v-if="preview.fieldType == 'text'">
                  {{ preview.data }}
                </h6>
              </div>
            </div>
          </div>
          <div class="modal_content">
            <div class="modal-footer d-inline-block w-100">
              <div class="d-flex justify-content-between align-items-end">
                <div style="    margin-bottom: -16px;
                margin-left: -16px;">
                  <canvas id="qrcode" style="width:200px;height: 200px;"></canvas>
                </div>
                <div>
                  <button @click="backquestion" class="btn btn-primary">
                    back
                  </button>
                  <button @click="nextquestion" class="btn btn-primary ml-1">
                    next
                  </button>
                  <button type="button" v-b-modal.modal-json class="btn btn-primary ml-1">
                    Open Json
                  </button>
                  <button type="button" @click="closejsonmodel()" class="btn btn-secondary ml-1" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>

          </div>
        </b-modal>
      </b-card>
    </div>
    <div v-if="tabIndex == 2">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
            </b-col>
          </b-row>
        </div>
        <b-card>
          <b-row>
            <b-col lg="6">
              <h4>Question Background</h4>
              <b-media no-body>
                <b-media-aside>
                  <b-img rounded :src="levelDetails.question_bg" height="120" width="180" />
                </b-media-aside>
                <b-media-body class="mt-75 ml-75">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                    v-b-modal.modal-xl-gallery-img>
                    <span class="d-none d-sm-inline">Select</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col lg="6">
              <h4>Question Video</h4>
              <b-media no-body>
                <b-media-aside>
                  <b-img rounded :src="levelDetails.question_thumbnail" height="120" width="180" v-if="levelDetails.question_video ==
      'https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png'
      " />
                  <div style="width: 200px; height: auto; object-fit: contain" v-else>
                    <b-embed type="video" aspect="4by3" controls :poster="levelDetails.question_thumbnail">
                      <source :src="levelDetails.question_video" type="video/webm" />
                      <source :src="levelDetails.question_video" type="video/x-m4v" />
                      <source :src="levelDetails.question_video" type="video/mp4" />
                      <source :src="levelDetails.question_video" type="video/avi" />
                    </b-embed>
                  </div>
                </b-media-aside>
                <b-media-body class="mt-75 ml-75">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                    v-b-modal.modal-xl-videos @click="selectImagesVideos('2')">
                    <span class="d-none d-sm-inline">
                      <span class="d-none d-sm-inline">Select</span></span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <b-card-text>Allowed mp4</b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" :disabled="saveButtonDisabled" @click="saveLevelDetails()">
                  <span class="text-nowrap">
                    {{ isloadingSave ? "Saving..." : "Save" }}
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormFile,
  VBTooltip,
  BTab,
  BTabs,
  BMediaAside,
  BMediaBody,
  BCardText,
  BImg,
  BEmbed,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import route from "@/router";
import axios from "axios";
import AddEditType from "./app/list/users-list/AddEditType.vue";
import AddNewType from "./app/list/users-list/AddNewType.vue";
import { BBreadcrumb } from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import S3 from "aws-s3";
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';

export default {
  components: {
    Question: () => import("@/views/components/question/Question.vue"),
    BOverlay,
    AddNewType,
    BBreadcrumb,
    AddEditType,
    BCard,
    BRow,
    VBTooltip,
    BCol,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BFormFile,
    BTab,
    BTabs,
    BCardText,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormCheckbox,
    BEmbed,
    JsBarcode,
    QRCode,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isAddEditTypeSidebarActive: false,
      imageTooltipText: "",
      currentindextable: "",
      imageTooltipbg: "",
      jsonShowdata: "",
      editdata: {},
      gallerySearchQuery: "",
      isAddNewTypeSidebarActive: false,
      searchQuery: "",
      table_: [],
      table_data: {},
      base_url: null,
      options: [
        { value: "Single_select", text: "Single select" },
        { value: "MUltiselect", text: "MUlti-select" },
        { value: "Drag_and_drop", text: "Drag_and_drop" },
      ],
      routedata: this.$route.params.levelId,
      indexdata: {
        id: "",
      },
      previewdata: {},
      deletequestion: {
        id: "",
        userName: "",
        categoryName: "",
        levelNo: "",
      },
      edithistory: {},
      restoreMessage: "",
      new_index: "",
      previewText: [],
      previewImage: [],
      previewArray: [],
      previewbackground: [],
      previewTitle: [],
      previewObject: [],
      previewQuestionType: [],
      feedbackData: [],
      show: true,
      tabIndex: 0,
      levelDetails: {
        question_video:
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png",
        question_bg:
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.61587843675732310.167758814713014460.38897381201168635.gif",
        question_thumbnail:
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png",
      },
      allVideos: [],
      selectVideoOrThumbnails: "",
      galleryImages: {},
      AllGalleryImages: [],
      isloadingSave: false,
      checkboxQuestion: [],
      allCheckBox: false,
      allLevels: [],
      loadingMoveQuestion: false,
      questionLatestStatus: {},
      publicStatus: false,
      revisionStatus: true,
      tabStatus: 'upload',
      items: [],
      themeQuestion: false,
    };
  },
  computed: {
    saveButtonDisabled() {
      return !(
        this.levelDetails.question_bg.length > 0 &&
        this.levelDetails.question_bg !=
        "https://zape02.s3.ap-southeast-1.amazonaws.com/0.61587843675732310.167758814713014460.38897381201168635.gif"
      );

    },
    moveButtonStatus() {
      let findStatus = this.checkboxQuestion.filter(
        (o) => o.checkBoxStatus == true
      );
      if (findStatus.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.themeQuestion = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_themes_question");
    if (this.$route.query.theme && this.$route.query.theme == 'true') {
      this.checkSelectedTab(0);
    }
    let id = this.$route.params.questionId;
    this.questionColorChange = id;
    this.getLevelDetails();
    this.gettabledata();
    this.recent_image_add_in_gallery();
  },
  methods: {
    moveQuestion() {
      this.loadingMoveQuestion = true;
      let questionMove = this.checkboxQuestion.filter((o) => {
        if (o.checkBoxStatus == true) {
          return o.id;
        }
      });
      let level = _.find(this.allLevels, { checkbox: true });
      axios
        .post(
          process.env.VUE_APP_API_URL + "/move-question",
          {
            levelId: level._id,
            questions: questionMove,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          if (get_response.data.status) {
            this.gettabledata();
            this.restoreMessage = get_response.data.message;
            this.showToasted("success");
            this.allCheckBox = false;
            this.loadingMoveQuestion = false;
            let checkBoxStatus = this.allLevels;
            for (let i = 0; i < this.allLevels.length; i++) {
              checkBoxStatus[i]["checkbox"] = false;
            }
            this.allLevels = checkBoxStatus;
            this.$root.$emit("bv::hide::modal", "move-questions");

          } else {
            this.restoreMessage =
              "There are some problem for moving question try again.";
            this.showToasted("danger");
          }
        })
        .catch((err) => console.error(err));
    },
    levelCheckBox(index) {
      let checkBoxStatus = this.allLevels;
      for (let i = 0; i < this.allLevels.length; i++) {
        if (index != i) {
          checkBoxStatus[i]["checkbox"] = false;
        }
      }
      this.allLevels = checkBoxStatus;
    },
    checkboxdata() {
      let checkBoxxArr = this.checkboxQuestion;
      for (let index = 0; index < checkBoxxArr.length; index++) {
        if (this.allCheckBox) {
          checkBoxxArr[index]["checkBoxStatus"] = true;
        } else {
          checkBoxxArr[index]["checkBoxStatus"] = false;
        }
      }
      this.checkboxQuestion = checkBoxxArr;
    },
    saveLevelDetails() {
      this.isloadingSave = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + `/level-details/save/${this.routedata}`,
          this.levelDetails,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          let response = get_response.data;
          if (response.status) {
            this.restoreMessage = response.message;
            this.showToasted("success");
            this.isloadingSave = false;
          } else {
            this.restoreMessage = "There are some problem try again.";
            this.showToasted("danger");
            this.isloadingSave = false;
          }
        });
    },
    saveQuestionBackground(params) {
      this.levelDetails.question_bg = params;
      this.$root.$emit("bv::hide::modal", "modal-xl-gallery-img");
    },
    getImages() {
      if (this.gallerySearchQuery.trim().length > 0) {
        return this.galleryImages.filter((item) => {
          if (item.image_name) {
            return this.gallerySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.AllGalleryImages;
      }
    },
    recent_image_used_in_gallery: function (page = 1, query) {
      (this.searchQuery = ""), (this.AllGalleryImages = []);
      (this.pageNewData = 0),
        (this.galleryImages = {}),
        query = this.gallerySearchQuery;
      axios
        .get(process.env.VUE_APP_API_URL + `/zape-cms/recent-used/pagination?page=${page}&query=${query}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.galleryImages = get_response.data;
          this.tabStatus = 'recent';
        });
    },
    filterGalleryImages(e) {
      if (this.tabStatus === 'upload') {
        console.log('if');
        this.recent_image_add_in_gallery(1, e);
      } else {
        console.log('else');

        this.recent_image_used_in_gallery(1, e);
      }

    },
    recent_image_add_in_gallery: function (page = 1, query) {
      (this.searchQuery = ""), (this.AllGalleryImages = []);
      (this.pageNewData = 0),
        (this.galleryImages = {}),
        query = this.gallerySearchQuery;

      axios
        .get(process.env.VUE_APP_API_URL + `/zape-cms/recent-add/pagination?page=${page}&query=${query}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.galleryImages = get_response.data;
          this.tabStatus = 'upload';
        });
    },
    preImage() {
      // console.log(this.pageNewData);
      if (this.pageNewData != 0) {
        this.AllGalleryImages = [];
        for (let index = 50; index > 0; index--) {
          // console.log(index)
          this.pageNewData = this.pageNewData - 1;
          // console.log(this.pageNewData);

          this.AllGalleryImages.push(this.galleryImages[this.pageNewData]);
        }
      }
    },
    nextImage() {
      this.AllGalleryImages = [];
      // console.log(this.pageNewData);
      if (this.galleryImages.length) {
        for (let index = 0; index < 50; index++) {
          // console.log(index)
          this.pageNewData = this.pageNewData + 1;
          // console.log(this.pageNewData);

          this.AllGalleryImages.push(this.galleryImages[this.pageNewData]);
        }
      }
    },
    saveQuestionVideosOrBg(params) {
      this.levelDetails.question_video = params.video_uri;
      this.levelDetails.question_thumbnail = params.videoThumbnail;
      this.$root.$emit("bv::hide::modal", "modal-xl-videos");
    },
    selectImagesVideos(param) {
      this.selectVideoOrThumbnails = param;
    },
    getLevelDetails() {
      axios
        .get(process.env.VUE_APP_API_URL + `/level-videos/${this.$route.params.levelId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.levels) {
            let checkBox = get_response.data.levels;
            for (let i = 0; i < get_response.data.levels.length; i++) {
              checkBox[i]["checkbox"] = false;
            }
            this.allLevels = checkBox;
          }
          this.allVideos = get_response.data.allVideos;
          if (get_response.data.levelDetails.question_video) {
            this.levelDetails.question_video =
              get_response.data.levelDetails.question_video;
          }
          if (get_response.data.levelDetails.question_bg) {
            this.levelDetails.question_bg =
              get_response.data.levelDetails.question_bg;
          }
          if (get_response.data.levelDetails.question_thumbnail) {
            this.levelDetails.question_thumbnail =
              get_response.data.levelDetails.question_thumbnail;
          }
        });
    },
    checkSelectedTab(index) {
      this.tabIndex = index;
    },
    dataSplitFunction(date) {
      let dateObj = {};
      let time = moment(date).local().format("hh:mm a");
      dateObj["time"] = time;
      let _date = moment(date).local().format("DD/MM/Y");
      dateObj["date"] = _date;
      return dateObj;
    },
    uploadQuestionImage(input) {
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.levelDetails.question_bg = data.location))
          .catch((err) => console.error(err));
      }
    },
    resetImage() {
      this.UserData.avatar = this.level_bg;
    },
    uploadQuestionVideo(input) {
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.levelDetails.question_bg = data.location))
          .catch((err) => console.error(err));
      }
    },
    resetVideo() {
      this.UserData.avatar = this.level_video_tumb;
    },
    questionsFilter() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          let finaldata = this.table_.filter((item) => {
            if (item.questionType && item.title && item.questionNo) {
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every(
                  (v) =>
                    item.title.toLowerCase().includes(v) ||
                    item.questionType.toLowerCase().includes(v) ||
                    item.questionNo.toString().toLowerCase().includes(v)
                );
            }
          });
          this.table_data = finaldata;
          this.show = false;
        } else {
          this.table_data = this.table_;
          this.show = false;
        }
      }, 1000);
    },
    restoreIEditQuestion(i) {
      let arr = {
        id: i,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/restoreEditQuestion", arr)
        .then((response) => {
          if (response.data == "successfully question restore") {
            this.gettabledata();
            this.restoreMessage = response.data;
            this.showToasted("success");
          } else {
            this.restoreMessage = "Question not restore";
            this.showToasted("danger");
          }
        });
      this.$root.$emit("bv::hide::modal", "modal-edit-history");
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.restoreMessage,
          variant,
        },
      });
    },
    EditHistory(i) {
      axios
        .get(process.env.VUE_APP_API_URL + `/edit-questions/history/${i}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
        .then((get_response) => {
          this.edithistory = get_response.data;
        });
    },
    preview_Edit_Question(index) {
      (this.previewText = []),
        (this.previewImage = []),
        (this.previewArray = []),
        (this.previewbackground = []),
        (this.previewTitle = []),
        (this.previewObject = []),
        (this.previewQuestionType = []);
      this.previewdata = this.edithistory[index];
      this.previewdata = JSON.stringify(this.previewdata);
      this.previewdata = JSON.parse(this.previewdata.replace(/null/g, '""'));
      this.converttojson(this.previewdata);
      this.currentindextable = index;
      var a = this.previewdata;
      var changeID = a._id;
      a["question_id"] = changeID;
      var backgrounddata = a.background;
      var questionTitle = a.title;
      var backgroundType = a.backgroundType;
      delete a.title;
      var Question_Type = a.questionType;
      delete a.backgroundType;
      delete a.backgroundImage;
      delete a.backgroundColor;
      delete a.background;
      delete a._id;
      delete a.id;
      delete a.level_id;
      delete a.questionType;
      delete a.question_id;
      delete a.updated_at;
      delete a.created_at;
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "title";
      colorpickerobject["fieldType"] = "title";
      colorpickerobject["mapto"] = [];
      colorpickerobject["data"] = questionTitle;
      pushallobject.push(colorpickerobject);
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          arrfinal = {};
          var newkey = "fieldname";
          arrfinal[newkey] = key;
          arrfinal["mapto"] = [];
          arrfinal["nested"] = [];
          if (typeof value == "string") {
            value.startsWith("http")
              ? (arrfinal["fieldType"] = "image")
              : (arrfinal["fieldType"] = "text");

            arrfinal["data"] = value;
          }
          if (typeof value == "number") {
            arrfinal["fieldType"] = "text";
            arrfinal["data"] = value;
          }
          if (typeof value == "object") {
            arrfinal["fieldType"] = "array";
            if (value.length == 0) {
              arrfinal["fieldType"] = "array";
              arrfinal["data"] = [];
            } else {
              for (let x = 0; x < value.length; x++) {
                createobject = {};
                var nested = "fieldname";
                var y = x + 1;
                createobject[nested] = key + "_" + y;
                createobject["mapto"] = [];
                var checkdatastart = value[x];
                if (typeof checkdatastart == "number") {
                  checkdatastart = checkdatastart.toString();
                  createobject["fieldType"] = "text";
                } else if (typeof checkdatastart == "object") {
                  checkdatastart = checkdatastart.value;
                  checkdatastart = checkdatastart.toString();
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                } else {
                  checkdatastart = checkdatastart;
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                }
                createobject["data"] = value[x];
                arrfinal["nested"].push(createobject);
              }
            }
          }

          pushallobject.push(arrfinal);
        }
      }
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "background";
      colorpickerobject["mapto"] = [];
      colorpickerobject["values"] = backgrounddata;
      colorpickerobject["data"] = backgroundType;
      pushallobject.push(colorpickerobject);
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "QuestionType";
      colorpickerobject["mapto"] = [];
      colorpickerobject["feildType"] = "questionType";
      colorpickerobject["data"] = Question_Type;
      pushallobject.push(colorpickerobject);
      for (let a = 0; a < pushallobject.length; a++) {
        if (pushallobject[a].fieldType == "image") {
          this.previewImage.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "text") {
          this.previewText.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "array") {
          for (let y = 0; y < pushallobject[a].nested.length; y++) {
            if (typeof pushallobject[a].nested[y].data == "string") {
              this.previewArray.push(pushallobject[a].nested[y]);
            } else {
              var data = pushallobject[a].nested[y].data.value;
              data = data.toString();
              data.startsWith("http")
                ? (pushallobject[a].nested[y].data["fieldType"] = "image")
                : (pushallobject[a].nested[y].data["fieldType"] = "text");
              this.previewObject.push(pushallobject[a].nested[y]);
            }
          }
        }
        if (pushallobject[a].fieldType == "title") {
          this.previewTitle.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "image") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "color") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].feildType == "questionType") {
          this.previewQuestionType.push(pushallobject[a]);
        }
      }
      this.previewdata = pushallobject;
    },
    mousebgimage(a) {
      this.imageTooltipbg = a;
    },
    mouseoption(a) {
      this.imageTooltipoption = a;
    },
    mouseOver(a) {
      this.imageTooltipText = a;
    },
    closejsonmodel() {
      this.new_index = "";
      this.$root.$emit("bv::hide::modal", "modal-lg");
    },
    Editquestionback() {
      if (this.currentindextable != "0") {
        this.currentindextable = this.currentindextable - 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.edithistory[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_Edit_Question(this.currentindextable);
      }
    },
    backquestion() {
      if (this.currentindextable != "0") {
        this.currentindextable = this.currentindextable - 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
      }
    },
    EditquestionNext() {
      if (this.currentindextable == 0) {
        this.currentindextable = this.currentindextable + 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.edithistory[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_Edit_Question(this.currentindextable);
      } else {
        var a = this.edithistory;
        if (this.currentindextable < a.length - 1) {
          this.currentindextable = this.currentindextable + 1;
          this.new_index = this.currentindextable;
          this.previewdata = this.edithistory[this.currentindextable];
          this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
          this.preview_Edit_Question(this.currentindextable);
        } else {
        }
      }
    },
    nextquestion() {
      if (this.currentindextable == 0) {
        this.currentindextable = this.currentindextable + 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
      } else {
        var a = this.table_data;
        if (this.currentindextable < a.length - 1) {
          this.currentindextable = this.currentindextable + 1;
          this.new_index = this.currentindextable;
          this.previewdata = this.table_data[this.currentindextable];
          this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
          this.preview_question(this.currentindextable);
        } else {
        }
      }
    },
    preview_question(index) {
      (this.previewText = []),
        (this.previewImage = []),
        (this.previewArray = []),
        (this.previewbackground = []),
        (this.previewTitle = []),
        (this.previewObject = []),
        (this.previewQuestionType = []);
      this.previewdata = this.table_data[index];
      delete this.previewdata.userId;
      delete this.previewdata.editby;
      delete this.previewdata.classId;
      delete this.previewdata.subjectId;
      delete this.previewdata.categoryId;
      this.previewdata = JSON.stringify(this.previewdata);
      this.previewdata = JSON.parse(this.previewdata.replace(/null/g, '""'));
      this.converttojson(this.previewdata);
      this.currentindextable = index;
      var a = this.previewdata;
      var changeID = a._id;
      a["question_id"] = changeID;
      var backgrounddata = a.background;
      var questionTitle = a.title;
      var backgroundType = a.backgroundType;
      delete a.title;
      var Question_Type = a.questionType;
      delete a.backgroundType;
      delete a.backgroundImage;
      delete a.backgroundColor;
      delete a.background;
      delete a._id;
      delete a.id;
      delete a.level_id;
      delete a.questionType;
      delete a.question_id;
      delete a.updated_at;
      delete a.created_at;
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "title";
      colorpickerobject["fieldType"] = "title";
      colorpickerobject["mapto"] = [];
      colorpickerobject["data"] = questionTitle;

      pushallobject.push(colorpickerobject);
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          arrfinal = {};
          var newkey = "fieldname";

          arrfinal[newkey] = key;

          arrfinal["mapto"] = [];
          arrfinal["nested"] = [];
          //  console.log(key);
          if (typeof value == "string") {
            // console.log("data1", typeof(value));
            value.startsWith("http")
              ? (arrfinal["fieldType"] = "image")
              : (arrfinal["fieldType"] = "text");

            arrfinal["data"] = value;
          }
          if (typeof value == "number") {
            arrfinal["fieldType"] = "text";
            arrfinal["data"] = value;
          }
          // console.log(typeof value);
          if (typeof value == "object") {
            arrfinal["fieldType"] = "array";
            if (value.length == 0) {
              // console.log("at length 0",value);
              arrfinal["fieldType"] = "array";
              arrfinal["data"] = [];
            } else {
              for (let x = 0; x < value.length; x++) {
                createobject = {};
                var nested = "fieldname";
                var y = x + 1;
                createobject[nested] = key + "_" + y;
                createobject["mapto"] = [];
                var checkdatastart = value[x];
                if (typeof checkdatastart == "number") {
                  checkdatastart = checkdatastart.toString();
                  createobject["fieldType"] = "text";
                } else if (typeof checkdatastart == "object") {
                  checkdatastart = checkdatastart.value;
                  checkdatastart = checkdatastart.toString();
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                } else {
                  checkdatastart = checkdatastart;
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                }

                createobject["data"] = value[x];

                arrfinal["nested"].push(createobject);
              }
            }
          }

          pushallobject.push(arrfinal);
        }
      }
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "background";
      colorpickerobject["mapto"] = [];
      colorpickerobject["values"] = backgrounddata;
      colorpickerobject["data"] = backgroundType;
      pushallobject.push(colorpickerobject);
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "QuestionType";
      colorpickerobject["mapto"] = [];
      colorpickerobject["feildType"] = "questionType";
      colorpickerobject["data"] = Question_Type;
      pushallobject.push(colorpickerobject);
      for (let a = 0; a < pushallobject.length; a++) {
        if (pushallobject[a].fieldType == "image") {
          this.previewImage.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "text") {
          this.previewText.push(pushallobject[a]);
        }
        if (pushallobject[a].fieldType == "array") {
          for (let y = 0; y < pushallobject[a].nested.length; y++) {
            if (typeof pushallobject[a].nested[y].data == "string") {
              this.previewArray.push(pushallobject[a].nested[y]);
            } else {
              var data = pushallobject[a].nested[y].data.value;
              data = data.toString();
              data.startsWith("http")
                ? (pushallobject[a].nested[y].data["fieldType"] = "image")
                : (pushallobject[a].nested[y].data["fieldType"] = "text");
              this.previewObject.push(pushallobject[a].nested[y]);
            }
          }
        }
        if (pushallobject[a].fieldType == "title") {
          this.previewTitle.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "image") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].data == "color") {
          this.previewbackground.push(pushallobject[a]);
        }
        if (pushallobject[a].feildType == "questionType") {
          this.previewQuestionType.push(pushallobject[a]);
          // console.log("previewbackground",this.previewbackground)
        }
      }
      this.previewdata = pushallobject;
      setTimeout(() => {
        QRCode.toCanvas(document.getElementById('qrcode'), this.table_[index]._id);
      }, 500);
      //console.log("aaaaa", a);
      // console.log(this.previewdata);
    },
    converttojson(jsondata) {
      this.jsonShowdata = JSON.stringify(jsondata, null, 2);
      // this.jsonShowdata = jsondata;
    },
    gettabledata: function () {
      const levelId = this.$route.params.levelId;
      const categoryId = this.$route.query.categoryId;
      let url = `${process.env.VUE_APP_API_URL}/getquestiondata/${levelId}`;
      if (categoryId !== undefined) {
        url += `/${categoryId}`;
      }
      axios
        .get(
          url,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          let checkBoxArr = [];
          for (let index = 0; index < get_response.data.levelsData.length; index++) {
            let questionObj = {
              id: get_response.data.levelsData[index]["_id"],
              checkBoxStatus: false,
            };
            checkBoxArr.push(questionObj);
          }
          this.checkboxQuestion = checkBoxArr;
          this.table_ = get_response.data.levelsData;
          this.table_data = get_response.data.levelsData;
          if (get_response.data.breadcrumbDetails && Object.keys(get_response.data.breadcrumbDetails).length > 0) {
            this.items = [
              {
                text: get_response.data.breadcrumbDetails.classData.className,
                to: { path: `/topics?class=${get_response.data.breadcrumbDetails.classData._id}` },
              },
              {
                text: get_response.data.breadcrumbDetails.subjectData.subjectName,
                to: { path: `/topics?class=${get_response.data.breadcrumbDetails.classData._id}&subject=${get_response.data.breadcrumbDetails.subjectData._id}` },
              },
              {
                text: get_response.data.breadcrumbDetails.categoryData.categoryName,
                to: { path: `/topics?class=${get_response.data.breadcrumbDetails.classData._id}&subject=${get_response.data.breadcrumbDetails.subjectData._id}&category=${get_response.data.breadcrumbDetails.categoryData._id}` },
              },
              {
                text: get_response.data.breadcrumbDetails.levelData.levelName,
                active: true,
              },
            ];
          }
          this.show = false;
        });
    },
    clickaddnewquestion: function (add_question_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/addquestiondata",
          add_question_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.questionLatestStatus = response.data;
          this.restoreMessage = "Question Successfully Added";
          this.$root.$emit('bv::show::modal', 'qr-code-question');
          setTimeout(() => {
            QRCode.toCanvas(document.getElementById('qrcode'), response.data._id);
          }, 500);
          this.showToasted("success");
          this.gettabledata();
        })
        .catch(function (error) {
          this.restoreMessage = "Try Again Question Not Added";
          this.showToasted("danger");
        });
    },
    updateQuestionStatus(param) {
      if (param == 1) {
        this.revisionStatus = false;
        this.questionLatestStatus.revisionStatus = true;
      } else {
        this.publicStatus = false;
        this.questionLatestStatus.revisionStatus = false;
      }
      axios
        .post(
          process.env.VUE_APP_API_URL + "/question/status",
          this.questionLatestStatus,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.restoreMessage = response.data.message;
          this.showToasted("success");
          this.publicStatus = false;
          this.revisionStatus = true;
          this.$root.$emit('bv::hide::modal', 'qr-code-question');
        })
        .catch(function (error) {
          this.restoreMessage = "Try Again Question Not Added";
          this.showToasted("danger");
        });
    },
    deletequestiondata(indexquestion) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var userdata = JSON.parse(localStorage.getItem("Datauser"));
          this.deletequestion.userName = userdata.user.name;
          this.deletequestion.deleteUserId = userdata.user._id;
          this.deletequestion.categoryName =
            localStorage.getItem("categoryName");
          this.deletequestion.levelNo =
            "Levels_" + localStorage.getItem("level");
          this.deletequestion.id = indexquestion;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/deletequestion",
              this.deletequestion
            )
            .then((response) => {
              this.restoreMessage = "Question Deleted Successfully";
              this.showToasted("success");
              this.gettabledata();
            });
        }
      });
    },
    feedback(questionId) {
      axios
        .get(
          process.env.VUE_APP_API_URL + `/User_FeedBack_Question/${questionId}`
        )
        .then((get_response) => {
          this.feedbackData = get_response.data;
        });
    },
    editquestiondata(question) {
      // console.log('question',question)
      //  question =  JSON.stringify(question);
      let questionClone = JSON.parse(JSON.stringify(question));
      // console.log('questionClone',questionClone)
      delete questionClone.userId;
      delete questionClone.editby;
      delete questionClone.questionNo;
      delete questionClone.classId;
      delete questionClone.subjectId;
      delete questionClone.categoryId;
      this.isAddEditTypeSidebarActive = true;
      // // alert(0);
      // console.log(question);
      this.editdata = questionClone;
    },
    ClickEditQuestiondata(edit_question_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/editquestion",
          edit_question_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.restoreMessage = "Question Successfully Edit";
          this.showToasted("success");
          // console.log(response);
          // this.gettabledata();
          this.gettabledata();
        })
        .catch(function (error) {
          this.restoreMessage = "Try Again Question Not Edit";
          this.showToasted("danger");
        });
    },
  },
  async beforeRouteLeave(to, from, next) {
      const questionComponent = this.$refs.questionComponent;
      if (questionComponent) {
        const [updatedQuestion, newQuestion] = await Promise.all([
          questionComponent.updateQuestionsChanges(),
          questionComponent.newQuestion()
        ]);

        if (updatedQuestion.length === 0 && newQuestion.length === 0) {
          next();
        } else {
          const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
          if (confirmLeave) {
            next();
          } else {
            next(false);
          }
        }
      } else {
        next()
      }
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_content {
  position: relative;
  z-index: 2;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}

.highlight-row {
  background: #ff4d4d;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
