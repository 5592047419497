<template>
  <b-sidebar id="add-new-type-sidebar" :visible="isAddNewTypeSidebarActive" bg-variant="white" width="50%" shadow
    no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-type-sidebar-active', val)">
    <template #default="{ hide }">
      <b-modal id="modal-xl" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>
            <b-col cols="12" md="6">

              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>

          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="backgroundImageSave(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="backgroundImageSave(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="backgroundImageSave(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="backgroundImageSave(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <b-modal id="modal-Arr-checkBox" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>

            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>

          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayCheckboxIndex(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayCheckboxIndex(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>
              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayCheckboxIndex(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayCheckboxIndex(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <b-modal id="cl-image" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>

          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                 <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayIndexObject(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayIndexObject(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
    selector: 'img',
    error:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
    loading:
      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
  }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayIndexObject(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayIndexObject(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add New Question</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider name="Question_Type" :rules="{ required: true, min: 3 }" v-slot="validationContext">
            <b-form-group id="example-input-group-3" label="Question Type" label-for="example-input-3">
              <b-form-select id="example-input-3" name="example-input-3" v-model="selected" :options="SelectType"
                v-on:change="checkType(selected)" aria-describedby="input-3-live-feedback"></b-form-select>
              <b-form-invalid-feedback id="input-3-live-feedback">{{
    validationContext.errors[0]
  }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-modal id="Play-video" no-close-on-backdrop hide-footer size="lg" centered
            :title="modalAudioVideo.name + 'Play Video'">
            <div class="modal_content">
            </div>
          </b-modal>
          <b-modal id="audio-video" no-close-on-backdrop hide-footer size="lg" centered :title="modalAudioVideo.name">
            <div class="modal_content">
              <div v-if="imgVideo == true">
                <b-button variant="primary" @click="changeimageVideo()"><feather-icon icon="ImageIcon" /></b-button>
              </div>
              <div v-else>
                <b-button variant="primary" @click="changeimageVideo()"><feather-icon icon="PlayIcon" /></b-button>
              </div>

              <div v-if="imgVideo == true" style="width: 100%; height: 320px">
                <b-embed type="iframe" aspect="21by9" :src="modalAudioVideo.video" allowfullscreen></b-embed>
              </div>

              <img v-else style="width: 100%; height: 320px" :src="modalAudioVideo.img" alt="" />
            </div>
          </b-modal>
          <div v-if="showImagesAndVideos == true">
            <b-row v-for="(tumbnailImages, thumbIndex) in tumbnailVideos" :key="thumbIndex">
              <b-col cols="12" md="6" style="padding: 2%; position: relative"
                v-for="(data, indexData) in tumbnailVideos[thumbIndex]" :key="indexData"
                v-b-tooltip.hover.top="imageTooltipText" v-on:mouseover="
    handleHover(data.imgTitle, thumbIndex, indexData)
    " v-on:mouseleave="handleHoverOut()">
                <div v-if="data.status" style="position: relative">
                  <img style="width: 100%; height: 130px; opacity: 0.3" :src="data.img" alt="" />
                  <div v-b-modal.audio-video @click="modalDataImageOrVideo(data)"
                    style="position: absolute; top: 32%; left: 15%">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="dark">
                      View
                    </b-button>
                  </div>
                  <div @click="checkType(data.imgTitle)" style="position: absolute; top: 32%; left: 50%">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="dark">
                      Use
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <img v-b-modal.audio-video @click="modalDataImageOrVideo(data)" style="width: 100%; height: 130px"
                    :src="data.img" alt="" />
                </div>
              </b-col>
            </b-row>
          </div>
          <div :key="sidebarkey">
            <span v-for="(formdata, index) in schemaData" :key="index">
              <div v-if="formdata.show == true">
                <h4>{{ formdata.fieldname }}</h4>
              </div>

              <div v-if="formdata.fieldType == 'text'">
                <div v-if="formdata.show == true">
                  <b-form-input id="example-input-2" name="example-input-2" v-model="formdata.data"></b-form-input>
                </div>
              </div>
              <div v-if="formdata.fieldType == 'image'">
                <div v-if="formdata.show == true">
                  <img style="width: 100px; height: 100px" :src="formdata.data" alt=""/> <br />
                  <br />
                  <b-button variant="primary" @click="showgalleryimage(index)" v-b-modal.modal-xl>
                    <span class="d-none d-sm-inline">Gallery Images</span>
                  </b-button>
                  <b-button style="margin-left: 10px" variant="primary" @click="backgroundFunction(index)">
                    <input :ref="'ref_Input' + index" type="file" accept="image/x-png,image/gif,image/jpeg"
                      class="d-none" @input="ImageRendererItems" />
                    <span class="d-none d-sm-inline">Upload</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                </div>
              </div>
              <div v-if="formdata.fieldType == 'backgroundImage'">
                <div v-if="formdata.show == true">
                  <img style="width: 100px; height: 100px" :src="formdata.data" alt="" />
                  <br />
                  <br />
                  <b-button variant="primary" v-b-modal.modal-xl @click="showgallerybackground(index)">
                    <span class="d-none d-sm-inline">Gallery Images</span>
                  </b-button>
                  <b-button style="margin-left: 10px" variant="primary" @click="backgroundFunction(index)">
                    <input :ref="'ref_Input' + index" type="file" accept="image/x-png,image/gif,image/jpeg"
                      class="d-none" @input="BackgroundPathChange" />
                    <span class="d-none d-sm-inline">Upload</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                </div>
              </div>
              <div v-if="formdata.fieldType == 'backgroundColor'">
                <div v-if="formdata.show == true">
                  <color-picker theme="light" :color="color" :sucker-hide="false" :sucker-canvas="suckerCanvas"
                    :sucker-area="suckerArea" @changeColor="changeColor" @openSucker="openSucker" />
                </div>
              </div>
              <div v-if="formdata.fieldType == 'dropdown'">
                <div v-if="formdata.show == true">
                  <b-form-select v-on:change="changebgtype(selected_bg_type, index)" v-model="selected_bg_type"
                    :options="formdata.values">
                  </b-form-select>
                </div>
                <div style="background: black; color: white">
                  {{ formdata.data }}
                </div>
              </div>
              <div v-if="formdata.fieldType == 'array'">
                <div v-if="formdata.show == true">
                  <span v-for="(nestedData, ind) in formdata.nested" :key="ind">
                    <div v-if="nestedData.value">
                      <div style="position: relative" v-if="nestedData.value.fieldType == 'text'">
                        <div v-if="nestedData.value.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.value.fieldname + "_" + ind }}
                          </h6>
                          <b-form-input style="margin-left: 10px; width: 40%" id="example-input-2"
                            name="example-input-2" v-model="nestedData.value.data"></b-form-input>
                        </div>

                        <div v-if="nestedData.isCorrect.fieldType == 'boolean'" style="
                            margin-left: 10px;
                            position: absolute;
                            left: 254px;
                            bottom: 4px;
                          ">
                          <div style="
                              background: #7367f0;
                              padding: 5px;
                              border-radius: 5px;
                              font-weight: 700;
                            " v-if="formdata.show == true">
                            <b-form-checkbox v-model="nestedData.isCorrect.data" v-on:change="checkboxdata(index, ind)">
                              <span style="color: white">
                                {{ nestedData.isCorrect.data }}
                              </span>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>

                      <div v-if="nestedData.value.fieldType == 'image'">
                        <div style="position: relative" v-if="nestedData.value.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.value.fieldname + "_" + ind }}
                          </h6>
                          <img style="width: 100px; height: 100px" :src="nestedData.value.data" />
                          <span v-if="nestedData.isCorrect.fieldType == 'boolean'">
                            <span style="
                                position: absolute;
                                top: 32%;
                                left: 25%;
                                background: #7367f0;
                                padding: 5px;
                                border-radius: 5px;
                                font-weight: 700;
                              " v-if="formdata.show == true">
                              <b-form-checkbox v-model="nestedData.isCorrect.data"
                                v-on:change="checkboxdata(index, ind)">
                                <span style="color: white">
                                  {{ nestedData.isCorrect.data }}
                                </span>
                              </b-form-checkbox>
                            </span>
                          </span>
                          <br />
                          <br />

                          <b-button variant="primary" @click="showgalleryArray(index, ind)"
                            v-b-modal.modal-Arr-checkBox>
                            <span class="d-none d-sm-inline">Gallery Images</span>
                          </b-button>
                          <b-button style="margin-left: 10px" variant="primary" @click="ArrayConvert(index, ind)">
                            <input :ref="'ref_InputE' + ind" type="file" accept="image/x-png,image/gif,image/jpeg"
                              class="d-none" @input="NestedPathChange" />
                            <span class="d-none d-sm-inline">Upload</span>
                            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="nestedData.fieldType == 'text'">
                        <div v-if="nestedData.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.fieldname }}
                          </h6>
                          <b-form-input style="margin-left: 10px" id="example-input-2" name="example-input-2"
                            v-model="nestedData.data"></b-form-input>
                        </div>
                      </div>

                      <div v-if="nestedData.fieldType == 'image'">
                        <div style="position: relative" v-if="nestedData.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.fieldname }}
                          </h6>
                          <img style="width: 100px; height: 100px" :src="nestedData.data" />

                          <br />
                          <br />

                          <b-button variant="primary" @click="Gllerydatashow(index, ind)" v-b-modal.cl-image>
                            <span class="d-none d-sm-inline">Gallery Images</span>
                          </b-button>
                          <b-button style="margin-left: 10px" variant="primary" @click="ArrayImagedata(index, ind)">
                            <input :ref="'ref_InputES' + ind" type="file" accept="image/x-png,image/gif,image/jpeg"
                              class="d-none" @input="NestedPathDataChnage" />
                            <span class="d-none d-sm-inline">Upload</span>
                            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </span>
          </div>
          <div>
            <div class="d-flex bg-light text-light align-items-center px-1 py-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit"
                :disabled="submitbutton">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="ml-2" type="button"
                variant="outline-secondary" @click="hide">
                Cancel
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BRow,
  BLink,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BEmbed,
  VBHover,
  BFormSelect,
  BBadge,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import S3 from "aws-s3";
import colorPicker from "@caohenghu/vue-colorpicker";
import { BTabs, BTab, BCardText } from "bootstrap-vue";

export default {
  components: {
    BSidebar,
    BCol,
    BEmbed,
    BForm,
    VBHover,
    BTabs,
    BTab,
    BCardText,
    BFormCheckbox,
    BBadge,
    BFormGroup,
    BRow,
    BLink,
    BImg,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    VBTooltip,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    colorPicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  model: {
    prop: "isAddNewTypeSidebarActive",
    event: "update:is-add-type-user-sidebar-active",
  },
  props: {
    isAddNewTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selected_bg_type: "",
      selected: "",
      Recent_use: [],
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      sidebarkey: 0,
      isSucking: false,
      reqQuestionData: {},
      SelectType: [],
      data: {},
      schemaData: "",
      searchQuery: "",
      showImage: false,
      removebutton: true,
      form_structure: {},
      showform: false,
      arrFormField: [],
      copyFormField: [],
      bg_text_type: false,
      bg_img_type: false,
      table_data: [],
      table_: {},
      optionindex: "",
      tumbnailVideos: [],
      ColorIndexArr: "",
      arrayIndexes: "",
      backgroundIndexImage: "",
      BgImage: "",
      checkBoxImageIndex: "",
      imageTooltipText: "",
      modalAudioVideo: {},
      imgVideo: false,
      showImagesAndVideos: true,
      tag: [],
      selectedTag: [],
      optionImageType: [
        {'value': 0 ,'text' :'100px'},
        {'value': 1 ,'text' :'300px'},
        {'value': 2 ,'text' :'500px'},
      ],
      selectImageType: 0,
    };
  },
  created() {
    this.get_question_type();
    this.recent_image_add_in_gallery();
    this.getAllTag();
  },
  computed: {
    submitbutton() {
      if (this.schemaData.length > 0) {
        for (let i = 0; i < this.schemaData.length; i++) {
          if (this.schemaData[i].fieldname == "title") {
            if (this.schemaData[i].data == null) {
              this.schemaData[i].data = "";
            }
            return !(
              this.schemaData.length > 0 && this.schemaData[i].data.length > 0
            );
          }
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    changeOptionImageResize() {
      this.forcRender();
    },
    deselectFunction() {
      var data = this.selectedTag;
      if (this.selectedTag.length > 0) {
        this.table_ = [];

        this.table_data = [];
        let arrIds = [];
        for (let index = 0; index < data.length; index++) {
          arrIds.push(data[index]._id);
        }
        let tagObject = {
          id: arrIds,
          tabId: 1,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            this.table_ = response.data;

            this.table_data = response.data;
          });
      } else {
      }
    },
    selectFunction(data) {
      this.table_ = [];

      this.table_data = [];
      let arrIds = [];
      for (let index = 0; index < data.length; index++) {
        arrIds.push(data[index]._id);
      }
      let tagObject = {
        id: arrIds,
        tabId: 1,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/gallery-tag/filter", tagObject, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          let dataArr = [];
          this.table_ = response.data;

          this.table_data = response.data;
        });
    },
    getAllTag() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.tag = res.data;
        });
    },
    changeimageVideo() {
      this.imgVideo = !this.imgVideo;
    },
    modalDataImageOrVideo(value) {
      this.modalAudioVideo = value;
      this.imgVideo = false;
    },
    handleHoverOut() {
      for (let index = 0; index < this.tumbnailVideos.length; index++) {
        for (let j = 0; j < this.tumbnailVideos[index].length; j++) {
          this.tumbnailVideos[index][j].status = false;
        }
      }
      this.imageTooltipText = "";
    },
    handleHover(i, j, z) {
      for (let index = 0; index < this.tumbnailVideos.length; index++) {
        for (let j = 0; j < this.tumbnailVideos[index].length; j++) {
          this.tumbnailVideos[index][j].status = false;
        }
      }
      this.tumbnailVideos[j][z].status = true;
      this.imageTooltipText = i;
    },
    preImage() {
      if (this.pageNewData != 0) {
        this.table_data = [];
        for (let index = 50; index > 0; index--) {
          this.pageNewData = this.pageNewData - 1;
          this.table_data.push(this.table_[this.pageNewData]);
        }
      }
    },
    nextImage() {
      this.table_data = [];
      if (this.table_.length) {
        for (let index = 0; index < 50; index++) {
          this.pageNewData = this.pageNewData + 1;
          this.table_data.push(this.table_[this.pageNewData]);
        }
      }
    },
    ArrayIndexObject(checkboximage) {
      this.Recent_use.push(checkboximage);
      this.schemaData[this.backgroundIndexImage].nested[
        this.checkBoxImageIndex
      ].data = checkboximage;

      this.searchQuery = "";

      this.$root.$emit("bv::hide::modal", "cl-image");
      this.forcRender();
    },
    ArrayCheckboxIndex(checkboximage) {
      this.Recent_use.push(checkboximage);
      this.schemaData[this.backgroundIndexImage].nested[
        this.checkBoxImageIndex
      ].value.data = checkboximage;

      this.searchQuery = "";
      //
      this.$root.$emit("bv::hide::modal", "modal-Arr-checkBox");
      this.forcRender();
    },
    addBackgroundModel() {
      this.Recent_use.push(checkboximage);
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.backgroundIndexImage == index) {
          this.schemaData[index].data = imgBg;
        }
      }
      this.BgImage = imgBg;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "modal-xl");
      this.forcRender();
    },
    closeBackgroundModel() {
      this.$root.$emit("bv::hide::modal", "modal-xl");
    },
    backgroundImageSave(imgBg) {
      this.Recent_use.push(imgBg);
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.backgroundIndexImage == index) {
          this.schemaData[index].data = imgBg;
        }
      }
      this.BgImage = imgBg;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "modal-xl");
      this.forcRender();
    },
    recent_image_used_in_gallery: function () {
      (this.searchQuery = ""), (this.table_data = []);
      (this.pageNewData = 0),
        (this.table_ = {}),
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-used/images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            // console.log(response.data);
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              // console.log(get_response.data[index]);
              this.table_data.push(get_response.data[index]);
              this.pageNewData = this.pageNewData + 1;
              //
            }
            this.forcRender();
          });
    },
    recent_image_add_in_gallery: function () {
      (this.searchQuery = ""), (this.table_data = []);
      (this.pageNewData = 0),
        (this.table_ = {}),
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-add/images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              this.table_data.push(get_response.data[index]);
              this.pageNewData = this.pageNewData + 1;
            }
          });
    },
    getimgdata: function () {
      (this.searchQuery = ""), (this.table_data = []);
      (this.pageNewData = 0),
        (this.table_ = {}),
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_data = get_response.data;
          });
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    ArrayImagedata(schemain, nestedin) {
      this.optionindex = schemain;
      this.arrayIndexes = nestedin;
      var target = "ref_InputES" + nestedin;
      var x = this.$refs[target];
      x[0].click();
    },
    ArrayConvert(schemain, nestedin) {
      this.optionindex = schemain;
      this.arrayIndexes = nestedin;
      var target = "ref_InputE" + nestedin;
      var x = this.$refs[target];
      x[0].click();
    },

    changeInd(schemaindex, nestedindex) {
      this.optionindex = schemaindex;
      this.arrayIndexes = nestedindex;
      var target = "ref_Input" + nestedindex;
      var x = this.$refs[target];
      x[0].click();
    },
    changeIndexOfImage(e) {
      this.optionindex = e;
      var target = "ref_InputI" + e;
      var x = this.$refs[target];
      x[0].click();
    },
    backgroundFunction(e) {
      // console.log("in backgroynd");
      this.optionindex = e;

      var target = "ref_Input" + e;
      //  console.log(this.$refs[target]);
      var x = this.$refs[target];
      //  console.log(x[0]);
      x[0].click();

      //.$el.click();
    },
    getImages() {
      // console.log('afaksfa',this.table_data);
      if (this.searchQuery.trim().length > 0) {
        return this.table_.filter((item) => {
          // console.log(item);
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
    showgallerybackground(i) {
      this.backgroundIndexImage = i;
    },
    showgalleryimage(i) {
      this.backgroundIndexImage = i;
    },
    hideimagegallery(i) {
      this.schemaData[i].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    hidegallery(i, j) {
      this.schemaData[i].nested[j].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    hideArraygallery(i, j) {
      this.schemaData[i].nested[j].value.showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    Gllerydatashow(i, j) {
      this.backgroundIndexImage = i;
      this.checkBoxImageIndex = j;
    },
    showgalleryArray(i, j) {
      this.backgroundIndexImage = i;
      this.checkBoxImageIndex = j;
    },
    checkboxdata(i, j) {
      this.schemaData[i].nested[j].isCorrect.showboolean = true;
      this.forcRender();
    },
    hidebackgroundgallery(i) {
      this.schemaData[i].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    galleryImg(data, i, j) {
      this.schemaData[i].nested[j].data = data;
      this.schemaData[i].nested[j].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    galleryImgArray(data, i, j) {
      this.schemaData[i].nested[j].value.data = data;
      this.schemaData[i].nested[j].value.showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.schemaData[index].fieldType == "backgroundColor") {
          this.schemaData[index].data = color.hex;
        }
      }
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    openSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    changebgtype(selectedType, arrIndex) {
      for (let index = 0; index < this.schemaData.length; index++) {
        if (index == arrIndex) {
          this.schemaData[index].data = selectedType;
        }
      }
      this.selected_bg_type = "";
    },
    checkType(typeName) {
      let selectType = typeName.replace(" ", "-");
      selectType = selectType.toLowerCase();
      this.selected = selectType;

      for (let index = 0; index < this.reqQuestionData.length; index++) {
        if (this.reqQuestionData[index].structure.name == selectType) {
          this.schemaData = this.reqQuestionData[index].schema;
          this.schemaData = JSON.stringify(this.schemaData);
          this.schemaData = JSON.parse(
            this.schemaData.replace(/url/g, "value")
          );
          for (let i = 0; i < this.schemaData.length; i++) {
            if (this.schemaData[i].fieldType == "image") {
              var newkey = "showImage";
              this.schemaData[i][newkey] = false;
            }
            if (this.schemaData[i].fieldType == "backgroundImage") {
              var newkey = "showImage";
              this.schemaData[i][newkey] = false;
            }

            if (typeof this.schemaData[i].nested == "object") {
              if (this.schemaData[i].nested.length != 0) {
                for (let j = 0; j < this.schemaData[i].nested.length; j++) {
                  if (this.schemaData[i].nested[j].fieldType) {
                    if (this.schemaData[i].nested[j].fieldType == "image") {
                      var newkey = "showImage";
                      this.schemaData[i].nested[j][newkey] = false;
                    }
                  }
                  if (this.schemaData[i].nested[j].value) {
                    if (
                      this.schemaData[i].nested[j].value.fieldType == "image"
                    ) {
                      var newkey = "showImage";
                      this.schemaData[i].nested[j].value[newkey] = false;
                    }
                  }
                  if (this.schemaData[i].nested[j].isCorrect) {
                    if (
                      this.schemaData[i].nested[j].isCorrect.fieldType ==
                      "boolean"
                    ) {
                      var newkey = "showboolean";
                      this.schemaData[i].nested[j].isCorrect[newkey] = false;
                      this.schemaData[i].nested[j].isCorrect.data = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.showImagesAndVideos = false;
    },

    resetForm() {
      this.showImagesAndVideos = false;
    },
    onSubmit() {
      var schemaFinalObject = {};
      var nestedfinalobject = {};
      var nestedfincalarr = [];
      var urlData = [];
      var iscorrects = [];
      var Objectcreatedarray = [];
      schemaFinalObject.level_id = this.$route.params.levelId;
      schemaFinalObject.Recent_used_images = this.Recent_use;
      for (let index = 0; index < this.schemaData.length; index++) {
        for (let [key, value] of Object.entries(this.schemaData[index])) {
          if (key == "fieldname") {
            if (this.schemaData[index].fieldType == "text") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "image") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "backgroundColor") {
              if (this.schemaData[index].data != null) {
                schemaFinalObject[value] = this.schemaData[index].data;
              } else {
                schemaFinalObject[value] = "demo";
              }
            }
            if (this.schemaData[index].fieldType == "dropdown") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "backgroundImage") {
              if (this.schemaData[index].data != null) {
                schemaFinalObject[value] = this.schemaData[index].data;
              } else {
                schemaFinalObject[value] = "demo";
              }
            }
            if (this.schemaData[index].fieldType == "array") {
              nestedfincalarr = [];
              Objectcreatedarray = [];
              urlData = [];
              iscorrects = [];
              for (let i = 0; i < this.schemaData[index].nested.length; i++) {
                if (this.schemaData[index].nested[i]) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i]
                  )) {
                    if (nestedkey == "fieldname") {
                      urlData = [];
                      iscorrects = [];
                      Objectcreatedarray.push(
                        this.schemaData[index].nested[i].data
                      );
                    }
                  }
                }
                if (this.schemaData[index].nested[i].value) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i].value
                  )) {
                    if (nestedkey == "fieldname") {
                      Objectcreatedarray = [];
                      urlData.push(this.schemaData[index].nested[i].value.data);
                    }
                  }
                }
                if (this.schemaData[index].nested[i].isCorrect) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i].isCorrect
                  )) {
                    if (nestedkey == "fieldname") {
                      Objectcreatedarray = [];
                      iscorrects.push(
                        this.schemaData[index].nested[i].isCorrect.data
                      );
                    }
                  }
                }
              }
              var newobject = {};

              for (let j = 0; j < urlData.length; j++) {
                newobject = {};
                newobject["value"] = urlData[j];
                newobject["isCorrect"] = iscorrects[j];
                Objectcreatedarray.push(newobject);
              }
              schemaFinalObject[value] = Objectcreatedarray;
            }
          }
        }
      }
      this.$emit("clickaddnewquestion", schemaFinalObject);

      this.Recent_use = [];
      this.$root.$emit("bv::toggle::collapse", "add-new-type-sidebar");
    },
    get_question_type: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/GET_ALL_QUESTION_TYPE_FORM", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.reqQuestionData = get_response.data.questionType;
          for (let index = 0; index < this.reqQuestionData.length; index++) {
            this.SelectType.push(this.reqQuestionData[index].structure.name);
            if (this.reqQuestionData[index].data) {
              for (
                let j = 0;
                j < this.reqQuestionData[index].data.length;
                j++
              ) {
                this.reqQuestionData[index].data[j]["status"] = false;
              }
              this.tumbnailVideos.push(this.reqQuestionData[index].data);
            }
          }
        });
    },
    BackgroundPathChange(data) {
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                this.schemaData[index].data = data.location;
                this.Recent_use.push(data.location);
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
    ImageRendererItems(data) {
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                this.schemaData[index].data = data.location;
                this.Recent_use.push(data.location);
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
    NestedPathDataChnage(data) {
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                var nest = this.schemaData[index].nested;
                for (let ind = 0; ind < nest.length; ind++) {
                  if (ind == this.arrayIndexes) {
                    this.schemaData[index].nested[ind].data = data.location;
                    this.Recent_use.push(data.location);
                  }
                }
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
    NestedPathChange(data) {
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                var nest = this.schemaData[index].nested;
                for (let ind = 0; ind < nest.length; ind++) {
                  if (ind == this.arrayIndexes) {
                    this.schemaData[index].nested[ind].value.data =
                      data.location;
                  }
                }
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
